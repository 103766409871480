@tailwind base;
@tailwind components;
@tailwind utilities;

body {
    height: 100%;
    margin: 0;
    font-family: "Inter var", "Lato", -apple-system, BlinkMacSystemFont, "Segoe UI", "Oxygen",
        "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
        sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

#root {
    height: 100%;
}

html {
    height: 100%;
    background-color: rgb(249 250 251);
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
        monospace;
}

.cancel-button {
    background: transparent; 
    border: none; 
    cursor: pointer; 
  }
  
.cancel-button:hover {
    background-color: transparent; 
}

hr {
    max-width: 100px;
    color: #6e776d;
    margin-left: 0;
}

.fadeIn {
    -webkit-animation: fadein 0.5s;
    /* Safari, Chrome and Opera > 12.1 */
    -moz-animation: fadein 0.5s;
    /* Firefox < 16 */
    -ms-animation: fadein 0.5s;
    /* Internet Explorer */
    -o-animation: fadein 0.5s;
    /* Opera < 12.1 */
    animation: fadein 0.5s;
}
